/** @format */

import React, { useContext, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { AppContext } from "../utils/ContextWrapper";
import { navigate } from "gatsby-link";

const IndexPage = () => {
  const context = useContext(AppContext);

  useEffect(() => {
    if (context.store.primeraVisita) {
      context.actions.setPrimeraVisita();
      navigate("/registrar/");
      return null;
    }
  }, [context.store.primeraVisita, context.actions]);

  return (
    <main>
      <title>Chapin Sin Gluten</title>
      <Layout>
        <div className="bienvenido-contenedor-principal">
          <StaticImage className="child-mujer" src="../images/mujer.png" alt="Mujer sonriendo" />
          <StaticImage className="child-no-glutten" src="../images/no-glutten.png" alt="No glutten" />
          <StaticImage className="child-ondas" src="../images/ondas-agua.png" alt="ondas" />
          <StaticImage className="child-smile" src="../images/curva-naranja.png" alt="sonrisa" />
          <Link className="bienvenido-boton" to="/productos/">
            <button className="btn-naranja ">COMPRAR</button>
          </Link>
          <div className="bienvenido-contenedor-vacio"></div>

          <div className="bienvenido-contenedor-texto">
            <h1 className="bienvenido-titulo-grande">¡Bienvenidos a la manera más libre de vivir!</h1>
            <p className="bienvenido-subtitulo text-justify">
              Creemos que nuestros productos sin gluten pueden mejorar la calidad de vida de nuestros clientes; libres
              de los efectos del trigo, sin sacrificar el placer de comer rico.
            </p>
          </div>
        </div>
      </Layout>
    </main>
  );
};

export default IndexPage;
